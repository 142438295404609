import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  ComboboxItem,
  Group,
  MultiSelect,
  SegmentedControl,
  Select,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useTaskAlertContext } from '@/pages/Task/TaskAlertProvider';
import { useUserSession } from '../shared/UserSessionContext';

import { AsyncAutocompleteOption, ResourceInput } from '@medplum/react';
import { createReference } from '@medplum/core';
import { Patient, Practitioner } from '@medplum/fhirtypes';
import { outreachTaskTypes, TaskType } from 'const-utils/codeSystems/ImaginePediatrics';
import { DateRangePicker } from '@/design-system/DateRangePicker';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

const taskTypeOptionsMap: Record<TaskType, string> = {
  [TaskType.CarePathwayReferralReview]: 'Care pathway referral review',
  [TaskType.ScreenerReview]: 'PRAPARE screener',
  [TaskType.ReviewBHSurvey]: 'BH Survey',

  [TaskType.Document]: 'Document',
  [TaskType.Chat]: 'Chat',
  [TaskType.Screener]: 'Screener',
  [TaskType.BHSurvey]: 'BH Survey',
  [TaskType.FirstCall]: ' First Call',
  [TaskType.Callback]: 'Call Back',
  [TaskType.InformationRequest]: 'Information Request',
  [TaskType.InformationRequestVerification]: 'Information Request Verification',
  [TaskType.CallPartneringProvider]: 'Call Partnering Provider or Agency',
  [TaskType.NetworkEngagement]: 'Network Engagement',
  [TaskType.OutreachFollowUp]: 'Outreach Follow Up',
  [TaskType.AppointmentRequest]: 'Appointment Request',
  [TaskType.SuicideRisk]: 'Suicide Risk',
  [TaskType.ScheduleAppointmentBHTOC]: 'Schedule Appointment BH TOC',
  [TaskType.BHTOC]: 'BH TOC',
  [TaskType.ScheduleVisitsForCarePathwayChange]: 'Schedule visits for care pathway change',
  [TaskType.CancelAllLongitudinalAppointments]: 'Cancel all longitudinal appointments',
  [TaskType.DisasterPreparednessSurvey]: 'Disaster Preparedness Survey',
  [TaskType.ReviewDisasterPreparednessSurvey]: 'Disaster Preparedness Survey',
};

const taskTypeOptions: ComboboxItem[] = Object.entries(taskTypeOptionsMap).map(([value, label]) => ({
  value,
  label,
}));

const supportedTaskTypes = [
  ...outreachTaskTypes,
  TaskType.CarePathwayReferralReview,
  TaskType.BHTOC,
  TaskType.ScreenerReview,
  TaskType.ReviewBHSurvey,
  TaskType.DisasterPreparednessSurvey,
  TaskType.ScheduleVisitsForCarePathwayChange,
  TaskType.CancelAllLongitudinalAppointments,
].map((v) => v.toString());

const relevantTaskTypeOptions = taskTypeOptions.filter((opt) => supportedTaskTypes.includes(opt.value));

export const TasksSearch = (): JSX.Element => {
  const {
    filters: searchFilters,
    setFilters: setSearchFilters,
    resolvedTasks,
    setResolvedTasks,
    setSelectedTaskId,
  } = useTaskAlertContext();
  const { markets } = useUserSession();

  const marketOptions = useMemo((): ComboboxItem[] => {
    return markets?.map((m) => ({ value: m.id!, label: m.name ?? '' }));
  }, [markets]);

  const flags = useFeatureFlags();

  const [dueDateStart, setDueDateStart] = useState<Date | undefined>();
  const [dueDateEnd, setDueDateEnd] = useState<Date | undefined>();

  useEffect(() => {
    setSearchFilters({
      ...searchFilters,
      dueDateStart: dueDateStart ? new Date(dueDateStart.toISOString()) : undefined,
      dueDateEnd: dueDateEnd ? new Date(dueDateEnd.toISOString()) : undefined,
    });
  }, [dueDateStart, dueDateEnd, searchFilters, setSearchFilters]);

  const theme = useMantineTheme();

  const handleTabChange = (value: string) => {
    setResolvedTasks(value === 'resolvedTasks');
    setSelectedTaskId(undefined);
    setDueDateStart(undefined);
    setDueDateEnd(undefined);
  };

  const CustomPractitionerItemComponent = forwardRef<HTMLDivElement, AsyncAutocompleteOption<Practitioner>>(
    ({ label, resource: _resource, active: _active, ...others }: AsyncAutocompleteOption<Practitioner>, ref) => {
      return (
        <div ref={ref} {...others}>
          <Group wrap="nowrap">
            <Text>{label}</Text>
          </Group>
        </div>
      );
    },
  );

  return (
    <>
      <Group gap="xs" w="100%" wrap="wrap">
        <Box flex="2">
          <ResourceInput
            defaultValue={searchFilters.patient}
            onChange={(p) => {
              setSearchFilters({ ...searchFilters, patient: p && createReference(p as Patient) });
            }}
            resourceType="Patient"
            name="patient"
            placeholder="Search by patient name"
            searchCriteria={{ _tag: 'patient' }}
          />
        </Box>

        <Box flex="2">
          <ResourceInput
            key={JSON.stringify(searchFilters.assignedTo)}
            defaultValue={searchFilters.assignedTo === 'unassigned' ? undefined : searchFilters.assignedTo}
            onChange={(p) => {
              setSearchFilters({ ...searchFilters, assignedTo: p && createReference(p as Practitioner) });
            }}
            resourceType="Practitioner"
            name="assigned-to"
            placeholder={resolvedTasks ? 'Resolved By' : 'Assigned to'}
            itemComponent={CustomPractitionerItemComponent}
            searchCriteria={{ '_tag:not': 'external' }}
          />
        </Box>

        <MultiSelect
          placeholder="Task"
          searchable
          data={relevantTaskTypeOptions}
          flex="2"
          onChange={(v) => {
            setSearchFilters({ ...searchFilters, taskType: v.join(',') });
          }}
          value={searchFilters.taskType?.split(',').filter(Boolean)}
          clearable
        />
        <Select
          placeholder="Market"
          data={marketOptions}
          flex="2"
          onChange={(v) => setSearchFilters({ ...searchFilters, market: v ?? undefined })}
          value={searchFilters.market}
          disabled={markets.length === 1}
          clearable
        />
        {!resolvedTasks && flags.TaskDueDateFilter && (
          <Box flex="4">
            <DateRangePicker
              setStartDate={(date) => {
                setDueDateStart(date ?? undefined);
              }}
              setEndDate={(date) => {
                setDueDateEnd(date ?? undefined);
              }}
              startDate={dueDateStart}
              endDate={dueDateEnd}
              clearable
              placeholder="Select Due Date range"
            />
          </Box>
        )}
        {
          <SegmentedControl
            value={resolvedTasks ? 'resolvedTasks' : 'openTasks'}
            onChange={handleTabChange}
            data={[
              { label: 'Open Tasks', value: 'openTasks' },
              { label: 'Resolved Tasks', value: 'resolvedTasks' },
            ]}
            bd="0.5px solid imagine-gray.2"
            styles={{
              root: {
                borderRadius: theme.radius.md,
              },
            }}
          />
        }
      </Group>

      <Group mt="sm">
        <Checkbox
          checked={searchFilters.assignedTo === 'unassigned'}
          onChange={(e) => {
            if (e.target.checked) {
              setSearchFilters({ ...searchFilters, assignedTo: 'unassigned' });
            } else {
              setSearchFilters({ ...searchFilters, assignedTo: undefined });
            }
          }}
          label={resolvedTasks ? 'Resolved by Care Hub System' : 'Only unassigned'}
        />
      </Group>
    </>
  );
};
